<template>
  <section id="dashboard-app-home">
    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col lg="12" md="6">
            <!-- <mint-new-nft-card :data="data.congratulations" /> -->
            <mint-action-card />
          </b-col>
          <!-- <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col> -->
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <!-- <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col> -->
      <!--/ Revenue Report Card -->
    </b-row>

    <!-- Company Table Card -->
    <b-row class="match-height">
      <b-col lg="12">
        <h3 class="my-2">
          {{ $t("lease2nft.featured") }}
          <small class="">
            <router-link class="card-link" :to="{ name: 'nft-invest' }">
              {{ $t("lease2nft.link_to_investments") }}
            </router-link>
          </small>
        </h3>
      </b-col>
      <!-- <ecommerce-company-table :table-data="data.companyTable" /> -->
    </b-row>
    <b-row class="match-height">
      <b-col sm="6" lg="4" v-for="nft in featuredNfts" :key="nft.id">
        <card-nft-summary
          md="6"
          :lease="getLeaseObj(nft.leases[0])"
          :nft="nft"
          isInvest
          v-if="true || nft.status == $t('nftStatus')['ACTIVE']"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";

// import MintNewNftCard from "./MintNewNftCard.vue";
import MintActionCard from "./MintActionCard.vue";

import { sync } from "vuex-pathify";

import * as init_leases from "@/libs/init_leases";
import * as init_nfts from "@/libs/init_nfts";

// import { db } from "@/db";
// import { refreshUserProfile } from "@/auth/utils";

let user = getUserData();
import { getArrElemById } from "@/libs/helpers";
import CardNftSummary from "@/views/card/app-custom/card-nft-summary.vue";

export default {
  components: {
    BRow,
    BCol,

    // MintNewNftCard,
    MintActionCard,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    CardNftSummary,
  },

  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),
    nfts: sync("nfts/list"),
    buyNftModal: sync("modals/buyNftModal"),

    featuredNfts: function () {
      let finalNfts = [];
      console.log("this featurelimit: ", this.featureLimit);
      for (let i = 0; i < this.featureLimit; i++) {
        if (
          this.nfts[i] &&
          this.nfts[i].status == this.$t("nftStatus.ACTIVE")
        ) {
          finalNfts.push(this.nfts[i]);
        }
      }

      return finalNfts;
    },
  },
  data() {
    return {
      // userData: user,
      featureLimit: 5,
    };
  },

  mounted() {
    console.log("user:", user);
    // refreshUserProfile(this.$store);
    init_leases.init();
    init_nfts.init();
  },
  methods: {
    getLeaseObj(lease) {
      if (lease && lease.id) {
        return getArrElemById(this.leases, "id", lease.id);
      } else {
        return null;
      }
    },
    userHasHolding(nft) {
      return true;
    },
  },
  created() {
    // data
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      // const userData = getUserData();
      this.data.congratulations.name =
        this.user && this.user.name
          ? this.user.name.split(" ")[0] || this.user.name
          : "";
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
