<template>
  <b-col class="mb-1" cols="12">
    <div class="mt-0">
      <small class="mb-0 h5 text-primary">
        {{ $t(title ? title : "") }}
      </small>
      <hr />
    </div>

    <div class="">
      <b-list-group>
        <b-list-group-item
          v-for="entity in entities"
          :key="entity.id"
          class="flex-column align-items-start"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              <b class="text-uppercase">
                {{ $t(entity.act_as) }}
              </b>
            </h5>
            <small class="text-secondary">
              <b-badge variant="primary" pill class="badge-round">
                {{ $t("entitySigned")[entity.signed] }}
              </b-badge>
            </small>
          </div>
          <b-card-text class="mb-1">
            {{ entity.email }}
            <!-- v-if="entity.signed != 1 && user.id == lease.user_id" -->
            <a
              v-if="entity.signed != 1"
              class="pl-1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="sendReminder(entity)"
              :aria-disabled="sending_in_progress"
            >
              <u v-show="!sending_in_progress">{{ $t("send reminder") }}</u>
              <u v-show="sending_in_progress">
                <b-spinner :label="$t('sending')" />
              </u>
            </a>
          </b-card-text>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BFormGroup,
  BCard,
  BListGroup,
  BListGroupItem,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
// import { dollarUSLocale } from "@/libs/financeHelper";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { sync } from "vuex-pathify";
import laravel from "@/libs/laravel";
// import * as init_nfts from "@/libs/init_nfts";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BCardText,
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
    BSpinner,
  },
  props: {
    lease: {
      type: Object,
      default: null,
    },
    nft: {
      type: Object,
      default: null,
    },
    entities: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    user: sync("user/profile"),
  },
  data() {
    return {
      sending_in_progress: false,
    };
  },
  methods: {
    submitSuccess(data) {
      // init_nfts.init();
      // setTimeout(() => this.$router.push({ name: "nft-portfolio" }), 1000);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Reminder sent",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    sendReminder(entity) {
      console.log("send nft signature reminder.", entity);

      // $url = '/api/nfts/{nft}/sign/remind/{entity}';
      this.sending_in_progress = true;

      laravel.nfts
        .remind(this.nft.id, entity.id)
        .then((resp) => {
          console.log(resp);
          // clear local storage
          this.submitSuccess(resp.data);
          // setTimeout(() => this.$router.push({ name: "nft-portfolio" }), 1000);
          setTimeout(() => {
            this.sending_in_progress = false;
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + error.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.sending_in_progress = false;
        });
    },
  },
};
</script>