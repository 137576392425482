<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon size="24" class="mr-1" icon="UploadCloudIcon" />
        <!-- <b-card-title class="ml-25">
          {{ $t("lease2nft.getstarted") }}
        </b-card-title> -->

        <b-card-title class="ml-25">
          {{ $t("howtoguide_brief.header") }}
        </b-card-title>
      </div>
      <!-- <feather-icon icon="UploadCloudIcon" size="18" /> -->
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline>
        <app-timeline-item
          variant="primary"
          v-for="step in $t('howtoguide_brief.steps')"
          :key="step.title"
        >
          <div
            class="
              d-flex
              justify-content-between
              flex-sm-row flex-column
              mb-sm-0 mb-1
            "
          >
            <h6>{{ step.title }}</h6>
          </div>
          <p v-for="item in step.items" :key="item">
            <feather-icon icon="CheckIcon" size="18" />
            {{ item }}
          </p>
        </app-timeline-item>
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>

    <h3 class="text-left mx-1">
      <b-link
        ><small>{{ $t("howtoguide") }}</small></b-link
      >
    </h3>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      large
      class="m-1"
      @click="$router.push({ name: 'create-lease-wizard' })"
    >
      {{ $t("lease2nft.mint_card_btn") }}
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BLink,
  BButton,
} from "bootstrap-vue";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import Ripple from "vue-ripple-directive";

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BLink,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      avatars: [
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
          name: "Billy Hopkins",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
          name: "Amy Carson",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
          name: "Brandon Miles",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
          name: "Daisy Weber",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
          name: "Jenny Looper",
        },
      ],
    };
  },
};
</script>
