<template>
  <div>
    <b-row v-if="!show_card">
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">
          <span v-if="!title">{{ $t("Confirm Create") }}</span>
          <span v-if="title">{{ $t(title) }}</span>
        </h5>
        <small class="text-muted">
          <!-- <span v-if="!title">{{ $t("Review and submit") }}</span>
          <span v-if="title">{{ $t(title) }}</span> -->
        </small>
      </b-col>

      <!-- general info -->
    </b-row>
    <b-row>
      <b-col v-if="!show_card" cols="12" class="mt-1">
        <small class="mb-0 h3 text-primary">
          {{ $t("General Info") }}
        </small>
        <hr />
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Type of Property') + ':'"
          label-for="v-property-type"
        >
          <span class="h5 text-uppercase">
            {{ lease && lease.property_type ? lease.property_type : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Type of Lease') + ':'"
          label-for="v-lease-type"
        >
          <span class="h5 text-uppercase">
            {{ lease && lease.lease_type ? lease.lease_type : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="!show_card">
        <b-form-group :label="$t('Address') + ':'" label-for="v-lease-adress">
          <span class="h5 text-uppercase">
            {{ lease && lease.address ? lease.address : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="12 mb-1">
        <span class="h5 text-uppercase">
          {{ lease && lease.description ? lease.description : "None" }}
        </span>
      </b-col>

      <b-col cols="12" class="mt-1" v-if="!show_card">
        <small class="mb-0 h3 text-primary">{{ $t("Terms") }}</small>
        <hr />
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="$t('Lease Type') + ':'"
          label-for="item-lease-type"
        >
          <span class="h5 text-uppercase">
            {{ lease && lease.terms_type ? lease.terms_type : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease Start')" label-for="v-lease-start">
          <span class="h5 text-uppercase">
            {{
              lease && lease.start_date
                ? removeTimeFromDate(lease.start_date)
                : "None"
            }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease End')" label-for="v-lease-end">
          <span class="h5 text-uppercase">
            {{
              lease && lease.end_date
                ? removeTimeFromDate(lease.end_date)
                : "None"
            }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Payment Frequency')"
          label-for="item-paym-freq"
        >
          <span class="h5 text-uppercase">
            {{ lease && lease.paym_freq ? lease.paym_freq : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
          <span class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ lease && lease.paym_freq_amt ? lease.paym_freq_amt : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Bill Day')" label-for="v-bill-day">
          <span class="h5 text-uppercase">
            {{ lease && lease.billing_day ? lease.billing_day : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Bond')" label-for="v-bond">
          <span class="h5 text-uppercase">
            {{ lease && lease.bond ? lease.bond : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <!-- lease documents -->
      <b-col v-if="!show_card" cols="12" class="mt-1">
        <small class="mb-0 h3 text-primary">{{ $t("Lease Documents") }}</small>
        <hr />
      </b-col>

      <b-col v-if="!show_card" cols="12">
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-for="file in documents"
            :key="file.name"
          >
            <span class="mr-1 text-success">
              <feather-icon icon="CheckCircleIcon" size="24" />
            </span>
            {{ getNameFromFileObj(file) }}
          </b-list-group-item>
        </b-list-group>
      </b-col>

      <form-entities-summary
        v-if="!show_card"
        :entities="lease && lease.leasors ? lease.leasors : []"
        :allowSelf="true"
        :title="$t('Leasors')"
        :subtitle="$t('Enter leasors information')"
      />

      <form-entities-summary
        v-if="!show_card"
        :entities="lease && lease.leasees ? lease.leasees : []"
        :allowSelf="true"
        :title="$t('Leasees')"
        :subtitle="''"
      />

      <form-entities-summary
        v-if="!show_card"
        :entities="lease && lease.guarantors ? lease.guarantors : []"
        :allowSelf="true"
        :title="$t('Guarantors')"
        :subtitle="''"
      />
    </b-row>
    <form-lease-terms-summary v-if="!show_card" :terms.sync="lease" />
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import { getNameFromFileObj, timeToGo } from "@/libs/helpers";

import FormEntitiesSummary from "@/views/forms/components/form-entities/FormEntitiesSummary";
import FormLeaseTermsSummary from "@/views/forms/components/form-leases/FormLeaseTermsSummary";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,

    FormEntitiesSummary,
    FormLeaseTermsSummary,
  },
  props: {
    // show summary for a card
    show_card: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: null,
    },
    lease: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    documents() {
      return this.lease && this.lease.documents ? this.lease.documents : [];
    },
  },
  mounted() {
    // console.log(
    //   "timetogo:",
    //   this.lease.end_date,
    //   " = ",
    //   timeToGo(this.lease.end_date)
    // );
  },
  methods: {
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },

    removeTimeFromDate: function (timeStr) {
      let dd = new Date(Date.parse(timeStr));
      return dd.getFullYear() + "-" + dd.getMonth() + "-" + dd.getDate();
    },
  },
};
</script>
