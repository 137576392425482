<template>
  <div :title="title" class="m-0 p-0 rounded">
    <b-row>
      <b-col cols="12">
        <b-form-group label-for="v-lease-adress">
          <span class="h5 text-uppercase">
            <feather-icon icon="MapPinIcon" size="18" />
            {{ lease && lease.address ? lease.address : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <summary-value
          :title="$t('My Allocation')"
          :value="selfHolding"
          type="number"
        />
      </b-col>

      <b-col cols="6">
        <summary-value
          :title="$t('My Allocation') + ' %'"
          :value="selfHoldingPercent"
          type="percent"
        />
      </b-col>

      <b-col cols="6">
        <summary-value
          :title="$t('Est Holding Value')"
          :value="showValuePerNft * selfHolding"
          type="money"
        />
      </b-col>

      <b-col cols="6">
        <summary-value-per
          :title="$t('Est Holding Income')"
          :value="showNftIncomePerFreqHolding"
          :freq="freq ? freq : ''"
          type="money"
        />
      </b-col>

      <show-unsigned-entities
        v-if="nft.status == $t('nftStatus.DRAFT')"
        :title="$t('Unsigned Entities')"
        :entities="lease ? lease.entities : []"
        :nft="nft ? nft : null"
        :lease="lease ? lease : null"
      />
    </b-row>
    <!-- toggle button -->
    <a
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-toggle
      :href="'#collapse-nft-' + nft.id"
      @click.prevent
      variant="outline-primary"
    >
      <u>{{ $t("more") }}</u>
    </a>

    <b-collapse :id="'collapse-nft-' + nft.id" class="mt-2">
      <b-row>
        <b-col cols="6">
          <summary-value
            :title="$t('Estimated Total Value')"
            :value="estTotalValue"
            type="money"
          />
        </b-col>
        <b-col cols="6">
          <summary-value-per
            :title="$t('Lease Amount')"
            :value="amount ? amount : 0"
            :freq="freq ? freq : ''"
            type="money"
          />
        </b-col>
        <b-col cols="6">
          <b-form-group :label="$t('Lease Start')" label-for="v-lease-start">
            <span class="h5 text-uppercase">
              {{
                lease && lease.start_date
                  ? removeTimeFromDate(lease.start_date)
                  : "None"
              }}
            </span>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group :label="$t('Lease End')" label-for="v-lease-end">
            <span class="h5 text-uppercase">
              {{
                lease && lease.end_date
                  ? removeTimeFromDate(lease.end_date)
                  : "None"
              }}
            </span>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            :label="$t('Payment Frequency')"
            label-for="item-paym-freq"
          >
            <span class="h5 text-uppercase">
              {{ lease && lease.paym_freq ? lease.paym_freq : "None" }}
            </span>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
            <span class="h5 text-uppercase">
              {{ $t("currencySign") }}
              {{ lease && lease.paym_freq_amt ? lease.paym_freq_amt : "None" }}
            </span>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group :label="$t('Bill Day')" label-for="v-bill-day">
            <span class="h5 text-uppercase">
              {{ lease && lease.billing_day ? lease.billing_day : "None" }}
            </span>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group :label="$t('Bond')" label-for="v-bond">
            <span class="h5 text-uppercase">
              {{ lease && lease.bond ? lease.bond : "None" }}
            </span>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <router-link
            :to="{
              name: 'show-lease-details',
              params: { leaseId: lease ? lease.id : 0 },
            }"
          >
            {{ $t("more details") }}
          </router-link>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import {
  BCollapse,
  BButton,
  VBToggle,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BRow,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { getNameFromFileObj, timeToGo, showInfoModal } from "@/libs/helpers";

// import ShowNftSupply from "@/views/card/app-custom/show-nft-supply.vue";
import SummaryValue from "@/views/card/app-custom/summary-value.vue";
import SummaryValuePer from "@/views/card/app-custom/summary-value-per.vue";
import ShowUnsignedEntities from "@/views/card/app-custom/show-unsigned-entities.vue";

import { sync } from "vuex-pathify";

import {
  estLeaseValue,
  dollarUSLocale,
  estValuePerNft,
  estIncomePerNftPerFreq,
  leaseDaysLeft,
  getMyHolding,
} from "@/libs/financeHelper";

export default {
  components: {
    BCardCode,
    BCol,
    BButton,
    BCardText,
    BCard,
    BCollapse,
    BCardText,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow,
    // ShowNftSupply,
    SummaryValue,
    SummaryValuePer,
    ShowUnsignedEntities,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props: {
    title: String,

    lease: {
      type: Object,
      default: null,
    },

    nft: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    user: sync("user/profile"),

    // nft holding this entity holds for this lease nft
    selfHolding() {
      // entity object that
      let hold = getMyHolding(this.user, this.lease, this.nft);
      return hold && hold.holding ? hold.holding : 0;
    },
    selfHoldingPercent() {
      // let supply = this.nft.supply ? this.nft.supply : 0;
      // this.selfHolding;
      return (this.selfHolding * 100) / (this.nft.supply ? this.nft.supply : 1);
    },
    showNftIncomePerFreqHolding() {
      return this.showNftIncomePerFreq * this.selfHolding;
    },
    showNftIncomePerFreq() {
      let val = estIncomePerNftPerFreq(
        this.showValuePerNft,
        this.daysLeft,
        this.freq
      );

      console.log(
        "showValuePerNft, daysLeft, freq, val: ",
        this.showValuePerNft,
        this.daysLeft,
        this.freq,
        val
      );

      return val;
    },
    freq() {
      return this.lease && this.lease.paym_freq ? this.lease.paym_freq : "";
    },
    amount() {
      return this.lease && this.lease.paym_freq_amt
        ? this.lease.paym_freq_amt
        : 0;
    },
    daysLeft() {
      if (!this.lease || !this.lease.start_date) return 0;
      return leaseDaysLeft(this.lease);
    },
    leaseTimeLeft() {
      if (this.lease && this.lease.start_date && this.lease.end_date) {
        return timeToGo(this.lease.start_date, this.lease.end_date);
      } else {
        return "";
      }
    },
    estTotalValue() {
      if (this.lease) return estLeaseValue(this.lease);
      else return 0;
    },
    showValuePerNft() {
      // console.log("show value per nft : ", );
      return estValuePerNft(this.estTotalValue, this.nft.supply);
    },
  },
  methods: {
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },

    removeTimeFromDate: function (timeStr) {
      let dd = new Date(Date.parse(timeStr));
      return dd.getFullYear() + "-" + dd.getMonth() + "-" + dd.getDate();
    },
  },
};
</script>



<style>
.card-body {
  padding: 1rem;
}
.card {
  margin: 0.3rem;
}
/* .border-w-1 {
  border-width: 1px !important;
} */
</style>