<template>
  <!-- card 1 -->
  <!-- card 1 -->
  <!-- <b-col md="6" lg="4"> -->
  <b-card>
    <b-card-body>
      <b-card-title
        class="mb-1 d-flex justify-content-between align-items-start"
      >
        <b-badge
          :variant="getNftStatusVariant(nft && nft.status ? nft.status : '')"
        >
          {{ $t("nftStatusText")[nft ? nft.status : ""] }}
        </b-badge>

        <a
          v-if="nft && nft.status == 'active'"
          class="secondary"
          target="_block"
          href="https://etherscan.io/address/0x3e7cc0fe837de855f139348a7f6f72f6c69c68a3"
          ><small>See NFT blockcahin records</small></a
        >
      </b-card-title>

      <card-nft-box :nft="nft" :lease="lease" />
    </b-card-body>

    <b-card-footer class="px-1 pb-1 d-flex justify-content-between">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="editNft()"
        v-show="canEdit && !isInvest"
      >
        <feather-icon icon="EditIcon" class="" />
        {{ $t("Edit") }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-danger"
        @click="sellNft()"
        v-if="nft.status == 'active' && !isInvest"
      >
        {{ $t("Sell") }}
        <!-- <feather-icon icon="MinusIcon" class="" /> -->
      </b-button>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        @click="buyNft()"
        v-if="nft.status == 'active'"
      >
        <!-- <feather-icon icon="PlusIcon" class="" /> -->
        {{ $t("Buy More") }}
      </b-button>

      <b-button
        v-if="nft.status == 'draft'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="success"
        @click="mintNft()"
        v-show="canEdit"
      >
        <feather-icon icon="AwardIcon" class="" />
        {{ $t("Mint NFT Btn") }}
      </b-button>
    </b-card-footer>
  </b-card>
  <!-- </b-col> -->
</template>
  

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCardFooter,
  BCardBody,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormLeaseSummary from "@/views/forms/components/form-leases/FormLeaseSummary.vue";
import CardNftBox from "@/views/card/app-custom/CardNftBox.vue";
// import BuyNftModal from "@/views/card/app-custom/BuyNftModal.vue";
// import SellNftModal from "@/views/card/app-custom/SellNftModal.vue";

import laravel from "@/libs/laravel";
import { sync } from "vuex-pathify";
import { delArrElem } from "@/libs/helpers";

export default {
  props: {
    lease: {
      type: Object,
      default: () => {},
    },
    nft: {
      type: Object,
      default: () => {},
    },
    compact: {
      type: Boolean,
      default: true,
    },
    rowStyle: {
      type: Boolean,
      default: true,
    },
    isInvest: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),
    buyNftModal: sync("modals/buyNftModal"),
    sellNftModal: sync("modals/sellNftModal"),

    canEdit() {
      if (
        this.user &&
        this.lease &&
        this.lease.user_id &&
        this.user.id == this.lease.user_id &&
        this.nft.status != "active"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    sellNft() {
      console.log("sell nft: ", this.nft);
      // let elemId = "buyNftModalId-" + this.nft ? this.nft.id : "id";
      this.sellNftModal.nft = this.nft;
      this.sellNftModal.lease = this.lease;
      this.$bvModal.show("sellNftModalId");
    },
    buyNft() {
      console.log("buy nft: ", this.nft);
      // let elemId = "buyNftModalId-" + this.nft ? this.nft.id : "id";
      this.buyNftModal.nft = this.nft;
      this.buyNftModal.lease = this.lease;
      this.$bvModal.show("buyNftModalId");
    },

    mintNft() {
      console.log("edit lease: ", this.lease);
      if (this.lease && this.lease.id) {
        this.$router.push({
          name: "mint-nft-wizard",
          params: { leaseId: this.lease.id },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error mint nft",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    deleteNft() {
      console.log("delete lease: ", this.lease);
      this.$bvModal
        .msgBoxConfirm(this.$t("Confirm Delete Body") + this.lease.address, {
          title: this.$t("Confirm Delete Title"),
          size: "lg",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log("confirm delete");
          laravel.leases
            .destroy(this.lease.id)
            .then((resp) => {
              console.log("destroy sucess: ", resp.data);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Lease deleted!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });

              // remove lease from leases array
              delArrElem(this.leases, resp.data, "id");
            })
            .catch((error) => {
              console.log("destroy error: ", error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fail deleting lease",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        });
    },
    viewLease() {
      console.log("edit lease: ", this.lease);
      if (this.lease && this.lease.id) {
        //
        this.$router.push({
          name: "show-lease-details",
          params: { leaseId: this.lease.id },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error showing lease",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    editNft() {
      console.log("edit lease: ", this.lease);
      if (this.lease && this.lease.id) {
        //
        // let url = "/form/edit/lease/" + this.lease.id
        this.$router.push({
          name: "edit-lease-wizard",
          params: { leaseId: this.lease.id },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error editing lease",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    editLease() {
      console.log("edit lease: ", this.lease);
      if (this.lease && this.lease.id) {
        //
        // let url = "/form/edit/lease/" + this.lease.id
        this.$router.push({
          name: "edit-lease-wizard",
          params: { leaseId: this.lease.id },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error editing lease",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    goToNft() {
      console.log("go to nfts: ", this.lease.nfts);
      // this.$router.push({
      //   name: "view-nft",
      //   params: { leaseId: this.lease.id },
      // });
    },
    getNftStatusVariant(status) {
      switch (status) {
        case "draft":
          return "warning";
          break;
        case "minted":
          return "primary";
          break;
        case "ready2mint":
          // code block
          return "info";
          break;
        case "active":
          return "success";
          break;
        case "complete":
          // code block
          return "dark";
          break;
        default:
          // code block
          return "info";
      }
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BBadge,

    // custom
    CardNftBox,
    // BuyNftModal,
    // SellNftModal,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.card-body {
  padding: 1rem;
}
.card {
  margin: 0.3rem;
}
</style>